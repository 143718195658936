// @see: https://gatsbyguides.com/tutorial/create-client-only-routes
// @see: https://www.gatsbyjs.org/docs/client-only-routes-and-user-authentication/
// @see: https://github.com/wiziple/gatsby-plugin-intl/issues/68
// @see: https://github.com/wiziple/gatsby-plugin-intl/issues/93

import React from 'react';
// import { Router } from '@reach/router';
import Layout from '../../components/layout';
// import PrivateRoute from '../components/s4n/AccountManagement/PrivateRoute/PrivateRoute';
import Cart from '../../components/s4n/Shop/ext/pages/Cart';

const Component = () => {
  return (
    <Layout className={`cart__page`}>

      <Cart />

      {/* This route is not client only route */}
      {/* {false && 
        <Router>
          <Cart path="/:locale/cart" />
        </Router>
      } */}
    </Layout>
  )
}

export default Component;